import React from 'react';
import PropTypes from 'prop-types';


export const InteractiveExtraContent = ({ data }) => {
  const { action } = data;
  console.log('data', data);
  const buttons = action?.buttons;
  return (
    <div className="interactive-extra-content">
      {buttons ?
            <div className={data?.type === 'button' ? 'interact-button-wrapper' : "interactive-wrapper"}>
              {buttons?.length && buttons.map((el, i) => {
                const valueButton = el?.type && el[el.type] && el[el.type]?.title;
                return (
                  valueButton ? <div key={el} className={`button-interactive ${((i < 2 && buttons.length > 1)) ? 'button-not-last' : 'button-last'}`}>
                    <span>{valueButton}</span>
                  </div> : <></>
                )
              }
              )}
            </div> : <></>
      }
    </div>
  )
};

InteractiveExtraContent.propTypes = {
  data: PropTypes.object,
};
