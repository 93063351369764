import styled from 'styled-components';

const ModalPreviewTemplateStyled = styled.div`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  padding: 100px 15px 15px 15px;

  .div-avatar-agent {
    margin-right: 10px;
  }

  .modal-content {
    background-color: ${(props) => props.theme.colors.white};
    margin: auto;
    border-radius: 8px;
    width: 1000px;
    max-width: 100%;
    display: flex;
    flex-flow: column;

    .modal-title {
      color: ${({ theme }) => theme.colors.primary.main};
      padding: 24px 24px 20px;
      font-size: 21px;
    }

    .modal-icon {
      color: #ed5656;
      width: 40px;
      height: 40px;
      margin-bottom: 12px;
    }

    hr {
      width: 100%;
      border: 0.5px solid #cecece;
      margin-bottom: 10px;
      margin-top: 16px;
    }

    .modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 24px 20px;
      flex: 2;
    }

    .modal-text {
      font-size: 14px;
      color: ${(props) => props.theme.colors.grey[200]};
      text-align: center;
      margin-top: 15px;
    }

    .little-padding {
      padding: 10px;
    }

    .agent-list-container {
      width: 100%;
      max-height: 400px;
      overflow: auto;
      padding: 25px;
      margin: 25px;
    }

    .agent-status {
      flex-basis: auto;
    }

    .agent-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .user-info {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .agent-avatar {
      border-radius: 50px;
      width: 46px;
      height: 46px;
    }

    .agent-name-email {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      overflow: hidden;
    }

    .agent-conversations {
      display: flex;
      flex-direction: column;
      flex-basis: auto;
    }

    .color-unavailable {
      color: red;
    }

    .color-available {
      color: green;
    }

    .modal-button-transfer {
      flex-basis: auto;
      border-radius: 4px;
      padding: 0 16px;
      border-width: 2px;
      border-color: ${(props) => props.theme.colors.primary.main};
      min-width: 100px;
      margin-left: 5px;
      height: 32px;
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      background-color: ${(props) => props.theme.colors.primary.main};
      box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
      color: ${(props) => props.theme.colors.secondary.main};

      &:hover {
        color: white;
        cursor: pointer;
      }

      &:active {
        background-color: ${(props) => props.theme.colors.secondary.light};
        border-color: ${(props) => props.theme.colors.secondary.light};
        border-color: transparent;
      }
      &:disabled {
        color: ${(props) => props.theme.colors.grey[200]};
        background-color: transparent;
        border-color: ${(props) => props.theme.colors.grey[200]};
        cursor: not-allowed;
        .upload-icon {
          fill: ${(props) => props.theme.colors.grey[200]};
        }
      }
    }

    .modal-buttons-row {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      align-items: flex-end;
      flex: 2;

      .modal-button-cancel {
        border: solid;
        border-width: 2px;
        border-radius: 4px;
        padding: 0 16px;
        margin-left: 5px;
        min-width: 100px;
        font-size: 14px;
        font-weight: 500;
        height: 32px;
        background-color: #fdfdfd;
        text-transform: uppercase;
        color: ${(props) => props.theme.colors.grey[100]};
        box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);

        &:hover {
          color: ${(props) => props.theme.colors.secondary.main};
          border-color: ${(props) => props.theme.colors.secondary.main};
          cursor: pointer;
        }

        &:active {
          background-color: ${(props) => props.theme.colors.grey[300]};
          color: ${(props) => props.theme.colors.primary.main};
        }
      }
      .modal-button-finish {
        border: solid;
        border-width: 2px;
        border-radius: 4px;
        border-color: ${(props) => props.theme.colors.red};
        padding: 0 16px;
        min-width: 100px;
        margin-left: 5px;
        height: 32px;
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        color: ${(props) => props.theme.colors.red};
        box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);

        &:hover {
          background-color: ${(props) => props.theme.colors.red};
          color: white;
          cursor: pointer;
        }

        &:active {
          background-color: ${(props) => props.theme.colors.red};
          color: white;
        }
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.bp.md}px) {
    padding: 15px;

    .modal-content {
      .agent-list-container {
        padding: 0;
      }

      .agent-item {
        padding-bottom: 30px;
        margin-bottom: 30px;

        .user-info {
          padding-top: 0;
          padding-left: 0;
        }

        &:last-child {
          padding-bottom: 0;
          margin-bottom: 0;
          border: 0;
        }
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.bp.sm}px) {
    .modal-content {
      width: 100%;
      min-height: 100%;

      .agent-item {
        border-bottom: 1px solid ${(props) => props.theme.colors.grey[300]};
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;
      }
    }
  }
`;

export { ModalPreviewTemplateStyled };
