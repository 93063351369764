import React from 'react';
import PropTypes from 'prop-types';
import { ClipLoader } from 'react-spinners';
import { ErrorIcon } from '../../../atoms';
import i18n from '../../../../assets/i18n';

export function MessageImageHolder({ isFetching, isDownloadError, imageSource, handleClick }) {
  return <div className="multimedia-holder">
    {isFetching && <ClipLoader size={40} loading={isFetching} />}
    {isDownloadError ? (
      <>
        <ErrorIcon className="error-icon" />
        <p className="bubble-info-text">{i18n.errors.imageFileNotAvailable}</p>
      </>
    ) : (
      imageSource && <img onClick={imageSource ? handleClick : () => {}} src={imageSource} alt="user img sent" />
    )}
  </div>
}

MessageImageHolder.propTypes = {
  isFetching: PropTypes.bool,
  isDownloadError: PropTypes.bool,
  imageSource: PropTypes.string,
  handleClick: PropTypes.func,
  imageStyle: PropTypes.string,
}