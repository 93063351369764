import React from 'react';
import PropTypes from 'prop-types';
import { ModalPreviewTemplateStyled } from './styled';
import { withI18n } from '../../../hocs';


const CancelButton = ({ onClick, label }) => (
  <button onClick={onClick} className="modal-button-cancel ts ts-normal" type="button">
    {label}
  </button>
);

CancelButton.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
};

const ModalPreviewTemplate = ({ show, i18n, agents, onClose, template }) => {
  const { title, close, /* body, noAvailableAgents, */  } = i18n.previewTemplateModal;
  console.log('templateToPreview', template);
  return (
    <ModalPreviewTemplateStyled show={show}>
      <div className="modal-content">
        <h2 className="modal-title">{title}</h2>
        <div className="modal-body">
          {/* <p className="modal-text">{agents && agents.length > 0 ? body : noAvailableAgents}</p> */}
           hola
          <hr />
          <div className="modal-buttons-row">
            {<CancelButton onClick={onClose} label={close} />}
          </div>
        </div>
      </div>
    </ModalPreviewTemplateStyled>
  );
};

ModalPreviewTemplate.propTypes = {
  show: PropTypes.bool,
  i18n: PropTypes.object,
};

export default withI18n(ModalPreviewTemplate);
