import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import { ResourcesService } from '../../../services';
import { MessageImageHolder } from '../multimedia-message-bubble/components/MessageImageHolder';
import { MessageVideoHolder } from '../multimedia-message-bubble/components/MessageVideoHolder';
import DocumentTemplateHeader from './DocumentTemplateHeader';
import LocationTemplateHeader from './LocationTemplateHeader';
import { MODAL_TYPE } from '../../../models';

const MultimediaContentTemplate = ({
  multimediaId, multimediaCode, resourceURL, onMultimediaClick, image, video, document, location, locationParameters,
}) => {

  const [isFetching, setIsFetching] = useState(false);
  const [resourceSource, setResourceSource] = useState();
  const [isDownloadError, setIsdownloadError] = useState(false);
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0.25,
  });
  const isMounted = useRef(true);
  const SCROLL_DELAY_TIME = 100;

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const getMultimedia = async () => {
    setIsFetching(true);
    setIsdownloadError(false);
    try {
      let idType = false;
      if (multimediaId !== null && multimediaId !== undefined) {
        idType = 'multimediaId';
      } else if (multimediaCode !== null && multimediaCode !== undefined) {
        idType = 'multimediaCode'
      }
      const url = idType ? await ResourcesService.getResource(idType === 'multimediaId' ? multimediaId : multimediaCode, idType === 'multimediaCode') : resourceURL;
      setResourceSource(url);
    } catch (error) {
      setIsdownloadError(true);
    } finally {
      setIsFetching(false);
    }
  };

  const fetchMultimedia = useCallback(getMultimedia, []);
  useEffect(() => {
    let timer;
    if (inView && !resourceSource && isMounted.current) {
      timer = setTimeout(() => {
        fetchMultimedia();
      }, SCROLL_DELAY_TIME);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [fetchMultimedia, inView, resourceSource]);


  const handleClick = () => {
    const action = image ? MODAL_TYPE.PREVIEW.IMAGE : MODAL_TYPE.PREVIEW.VIDEO;
    onMultimediaClick(action, resourceSource);
  };

  return (
    <div ref={ref}>
      {image && <MessageImageHolder
        isFetching={isFetching}
        isDownloadError={isDownloadError}
        imageSource={resourceSource}
        handleClick={handleClick}
      />}
      {video && (<MessageVideoHolder
        isFetching={isFetching}
        isDownloadError={isDownloadError}
        videoSource={resourceSource}
        handleClick={handleClick}
      />
      )}
      {document && <DocumentTemplateHeader
        viewRef={ref}
        docSource={resourceSource}
        isFetching={isFetching}
        isDownloadError={isDownloadError}
      />}
      {location && <LocationTemplateHeader
        viewRef={ref}
        locationParameters={locationParameters}
        isFetching={isFetching}
        isDownloadError={isDownloadError}
      />}
    </div>
  );
};
MultimediaContentTemplate.propTypes = {
  multimediaId: PropTypes.number,
  multimediaCode: PropTypes.number,
  resourceURL: PropTypes.string,
  onMultimediaClick: PropTypes.func,
  image: PropTypes.bool,
  video: PropTypes.bool,
  document: PropTypes.bool,
  location: PropTypes.bool,
  locationParameters: PropTypes.object,
};
export default MultimediaContentTemplate;