import qs from 'qs';
import jwtDecode from "jwt-decode";
import { jws } from 'jsrsasign';
import { addTokenHeader, removeTokenHeader, get, post, fetchDelete, put } from './utils';
import * as ApiConfig from '../config/api';
import { STORAGE_ITEM } from '../models';

export const tokenToAxiosHeader = (token) => {
  return addTokenHeader(token);
};

export const removeAxiosToken = () => {
  return removeTokenHeader();
};

export const getWAToken = (config) => {
  const url = ApiConfig.AUTH_AGENT_EP;
  return get(url, config);
};

/**
  * Request a password reset for the user
  * @param  {string}  email
  * @return {Promise}
  */
export const requestPasswordReset = async (email) => {

  const url = `${ApiConfig.ADMIN_BASE_URL}/v1/user/recover`;
  const options = {
    method: 'POST',
    headers: new Headers({
      'Content-type': 'application/json',
    }),
    body: JSON.stringify({
      email,
    }),
  };

  try {
    const response = await fetch(url, options);
    await response.json();
    if (response.ok) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
}

export const logIn = async ({ mail, password }) => {

const longLived2FaToken = localStorage.getItem(STORAGE_ITEM.LONGLIVED2FATOKEN);
  const options = {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      mail,
      password,
    }),
  };
  if (longLived2FaToken && jwtDecode(longLived2FaToken) && jwtDecode(longLived2FaToken).email === mail) {
    options.headers = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${longLived2FaToken}`,
    })
  }

  try {
    const response = await fetch(`${ApiConfig.ADMIN_BASE_URL}/v1/auth`, options);
    const resData = await response.json();
    if (response.ok && resData) {
      if (resData && resData.data && resData.data.shortLived2FaToken) {
        const decodedToken = jwtDecode(resData?.data?.shortLived2FaToken);
        const expDate = decodedToken?.exp;


        localStorage.setItem(STORAGE_ITEM.SHORT_TOKEN, JSON.stringify({
          timeStamp: expDate,
          shortLived2FaToken: resData.data.shortLived2FaToken
        }));
        return { data: resData.data };
      }
        const data = {
          isUserLoggedIn: true,
          userToken: resData.data.accessToken,
          userTokenInfo: jws.JWS.parse(resData.data.accessToken).payloadObj,
        };
        const userData = resData.data.user || null;
        return { data, user: userData };
    }
    return { ...resData, ok: false };
  } catch (error) {
    return false;
  }



};

/**
   * Authenticates a user using Short lived code and totp
   * @param {string} totp
   * @param {string} shortlivedToken
   */
export const authUserTotp = async (totp, shortlivedToken) => {
  const url = `${ApiConfig.ADMIN_BASE_URL}/v1/auth/2fa/validate`;
  const options = {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${shortlivedToken}`,
    }),
    body: JSON.stringify({
      totp,
      method: 'email',
    }),
  };

  try {
    const response = await fetch(url, options);
    const resData = await response.json();
    if (response && response.ok && resData) {
        const data = {
          isUserLoggedIn: true,
          userToken: resData && resData.data && resData.data.accessToken,
          userTokenInfo: jws.JWS.parse(resData?.data?.accessToken).payloadObj,
        };
        const userData = (resData && resData.data && resData.data.user) || null;
        localStorage.setItem(STORAGE_ITEM.LONGLIVED2FATOKEN, resData && resData.data && resData.data.longLived2FaToken);
        localStorage.removeItem(STORAGE_ITEM.SHORT_TOKEN);
        return { ...data, user: userData };
    }
    return { ...resData, ok: false };
  } catch (error) {
    return false;
  }
}


export const refreshShortLivedToken = async (token) => {
  const url = `${ApiConfig.ADMIN_BASE_URL}/v1/auth/2fa/refresh`;

    const options = {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    };

    try {
      const response = await fetch(url, options);
      const resData = await response.json();
      if (response?.ok) {
        if (resData?.data?.shortLived2FaToken) {
          const decodedToken = jwtDecode(resData?.data?.shortLived2FaToken);
          const expDate = decodedToken?.exp;
          const objectToken = { timeStamp: expDate, shortLived2FaToken: resData.data.shortLived2FaToken };
          localStorage.setItem(STORAGE_ITEM.SHORT_TOKEN, JSON.stringify(objectToken));
          return objectToken;
        }
        return false;
      }
    } catch (error) {
      return false;
    }
    return false;
};


const downloadMultimediaData = (id, config, isMultimediaCode) => {
  const idProp = isMultimediaCode ? 'multimediaCode' : 'multimediaId';
  const query = qs.stringify({ [idProp]: id }, { addQueryPrefix: true });
  const url = `/v1/chat/multimedia/download${query}`;
  return get(url, config);
};

export const getUsers = (config) => {
  const url = `/v1/user`;
  return get(url, config);
};
export const getUser = (id) => {
  const url = `/v1/user/${id}`;
  return get(url);
}

export const getApplications = (accountId, limit = 100, page = 0) => {
  const query = qs.stringify({ limit, page, accountId }, { addQueryPrefix: true });
  const url = `/v1/application${query}`;
  return get(url);
};

export const getMultimediaResource = async (id, isMultimediaCode) => {
  let multimediaData;
  const applicationId = localStorage.getItem(STORAGE_ITEM.APP_ID);
  try {
    const { data } = await downloadMultimediaData(id, { params: { applicationId }, responseType: 'blob' }, isMultimediaCode);
    multimediaData = data;
  } catch (error) {
    throw new Error(error);
  }
  return multimediaData;
};

export const postMultimedia = (channel, file, multimediaType, source = 'output_agent', description = 'Empty description') => {
  const applicationId = localStorage.getItem(STORAGE_ITEM.APP_ID);
  const url = `/v1/chat/multimedia?applicationId=${applicationId}`;

  const formData = new FormData();

  formData.append('channel', channel);
  formData.append('file', file);
  formData.append('applicationId', applicationId);
  formData.append('description', description);
  formData.append('multimediaType', multimediaType);
  formData.append('source', source);

  return post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
};

export const getAccountId = (appId) => {
  const url = `/v1/application/${appId}`;
  return get(url);
};

export const getAgentsList = (accountId, limit = 100, page = 0) => {
  const url = `/v1/user?limit=${limit}&page=${page}&chatPermission=agent&accountId=${accountId}`;
  return get(url);
};

// templates
export const getTemplates = (appID) => {
  const url = `v1/chat/cannedmessage?applicationId=${appID}`;
  return get(url);
};

export const getTemplate = (appId, permission) => {
  const url = `v1/chat/cannedmessage?applicationId=${appId}&chatPermission=${permission}`;
  return get(url);
}

export const postTemplate = async (appId, formData) => {
  const url = `v1/chat/cannedmessage?applicationId=${appId}`;
  return post(url, formData);
};

export const deleteTemplate = (appId, fragmentId) => {
  const url = `v1/chat/cannedmessage/${fragmentId}?applicationId=${appId}`;
  return fetchDelete(url);
};

export const banUsers = (applicationId, formData) => {
  const url = `v1/chat/conversationsession/block?applicationId=${applicationId}`;
  return post(url, formData);
};

export const getBannedUsers = (applicationId) => {
  const url = `v1/chat/conversationsession/block?applicationId=${applicationId}`;
  return get(url);
};

export const unbanUsers = (applicationId, formData) => {
  const url = `v1/chat/conversationsession/unblock?applicationId=${applicationId}`;
  return put(url, formData);
};
export const sendTemplate = async (appId, token, template) => {

  const request = new Request(`${ApiConfig.ADMIN_BASE_URL}/v1/chat/send?applicationId=${appId}`, {
    method: 'POST',
    body: JSON.stringify(template),
    headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }),
  });
  const response = await fetch(request);
  return response;
};
// eslint-disable-next-line default-param-last
const obtainContacts = (applicationId, limit = 100, find, lastId) => {
  const url = `/v1/chat/contacts?applicationId=${applicationId}&limit=${limit}${lastId ? `&lastId=${lastId}` : '&page=0'}${find && `&find=${find}`}&orderBy=lastInputMessage-desc`;
  return get(url);
};
export const getContacts = async (applicationId, limit, find, lastId) => {
  let contacts;
  try {
    const resp = await obtainContacts(applicationId, limit, find, lastId);
    if (resp && resp.status === 200) {
      contacts = resp.data;
    }
  } catch (error) {
    throw new Error(error);
  }
  return contacts;
};
const obtainTopics = (applicationId, find, limit = 500, page = 0) => {
  const url = `/v1/chat/topic?applicationId=${applicationId}&limit=${limit}&page=${page}${find && `&find=${find}`}&orderBy=a-z`;
  return get(url);
};
export const getTopics = async (applicationId, find) => {
  let topics;
  try {
    const resp = await obtainTopics(applicationId, find);
    if (resp && resp.status === 200) {
      topics = resp.data;
    }
  } catch (error) {
    throw new Error(error);
  }
  return topics;
}
export const postTopic = async (applicationId, name, code) => {
  const url = `v1/chat/topic?applicationId=${applicationId}`;
  const body = { name, code };
  
  try {
    const resp = await post(url, body);
    if (resp?.status === 200) {
      const topic = resp?.data?.data;
      return { 
        ok: true, 
        data: { name: topic?.name, code: topic?.code, id: topic?.id } 
      };
    }
    if (!resp.ok) {
      throw new Error(`Error ${resp.status}: ${resp.statusText}`);
    }
  } catch (error) {
    return { ok: false };
  }
  return { ok: false };
};

export const deleteTopic = async (appId, topicId) => {
  const url = `v1/chat/topic/${topicId}?applicationId=${appId}`;
  
  try {
    const resp = await fetchDelete(url);
    if (!resp.ok) {
      throw new Error(`Error ${resp.status}: ${resp.statusText}`);
    }
    
    return { ok: true };
  } catch (error) {
    // Handle error
    return { ok: false };
  }
};

export const putTopic = async (applicationId, topic) => {
  const url = `v1/chat/topic/${topic?.id}?applicationId=${applicationId}`;
  const body = { name: topic?.name, code: topic?.code };
  
  try {
    const resp = await put(url, body);
    if (resp?.status === 200) {
      const topicResp = resp?.data?.data;
      return { 
        ok: true, 
        data: { name: topicResp?.name, code: topicResp?.code, id: topicResp?.id } 
      };
    }
    if (!resp.ok) {
      throw new Error(`Error ${resp.status}: ${resp.statusText}`);
    }
  } catch (error) {
    // Handle error
    return { ok: false };
  }
  return { ok: false };
};

export const subscribeTopics = async (applicationId, topics, convId) => {
  const url = `v1/chat/contacts/${convId}/topics/subscribe?applicationId=${applicationId}`;
  
  try {
    const resp = await put(url, {topics});
    if (resp?.status === 200) {
      return { 
        ok: true, 
      };
    }
    if (!resp.ok) {
      throw new Error(`Error ${resp.status}: ${resp.statusText}`);
    }
  } catch (error) {
    // Handle error
    return { ok: false };
  }
  return { ok: false };
};
export const unsubscribeTopics = async (applicationId, topics, convId) => {
  const url = `v1/chat/contacts/${convId}/topics/unsubscribe?applicationId=${applicationId}`;
  
  try {
    const resp = await put(url, {topics});
    if (resp?.status === 200) {
      return { 
        ok: true, 
      };
    }
    if (!resp.ok) {
      throw new Error(`Error ${resp.status}: ${resp.statusText}`);
    }
  } catch (error) {
    // Handle error
    return { ok: false };
  }
  return { ok: false };
};

/* unsubscribeTopics = async (tagsToDelete, convId) => {
  const applicationId = parseInt(localStorage.getItem(STORAGE_ITEM.APP_ID), 10);
  const unsubscribedTopics = await api.unsubscribeTopics(applicationId, tagsToDelete, convId);
  return unsubscribedTopics;
};
subscribeTopics = async (tagsToDelete, convId) => {
  const applicationId = parseInt(localStorage.getItem(STORAGE_ITEM.APP_ID), 10);
  const subscribedTopics = await api.subscribeTopics(applicationId, tagsToDelete, convId);
  return subscribedTopics;
}; */