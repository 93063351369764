export default {
  auth: {
    loading: 'Loading...',
    waContCent: 'WhatsApp Control Center',
  },
  errors: {
    unauthorized:
      'You do not have the corresponding permissions to access the tool. If it is a mistake, contact your account administrator or Indigitall support department.',
    goBackToIndigitall: 'Back to Indigitall',
    imageFileNotAvailable: 'Image no longer available',
    videoFileNotAvailable: 'Video no longer available',
    locationFileNotAvailable: 'Map no longer available',
    documentFileNotAvailable: 'Document no longer available',
    audioFileNotAvailable: 'Audio no longer available',
    videoNotSupported: 'Your browser does not support this video',
    socketDisconnected:
      'There was a problem with the connection. Please refresh the page. If the problem persists, contact the administrator or Indigitall support team.',
    fileTooBig: 'The file is too large. Please choose another file.',
    fileNoValid: 'The selected file is not valid. Please choose another file.',
    tooltipError: "There's been a problem. Message not sent.",
    noMatch: 'Page not found. Click on the button below to be redirected to the home page.',
    banUser: 'There has been a problem banning a user',
    requiredField: 'Required field',
    generalError: 'An error has occurred',
  },
  agentStats: {
    title: 'Statistics',
    waitingTime: 'Average waiting time: ',
    maxWaitingTime: 'Maximum waiting time: ',
    average: 'average',
    max: 'm max.',
    queuedUsers: 'Queued Users: ',
    onlineAgents: 'Online Agents: ',
  },
  chats: {
    createLabel: 'Create label',
    editLabel: 'Edit',
    deleteLabel: 'Delete',
    placeholderSelect: 'Select labels...',
    search: 'Search...',
    ago: 'ago',
    day: 'day',
    month: 'month',
    year: 'year',
    conversations: 'Conversations',
    allConversations: 'All conversations',
    conversation: 'Conversation',
    singleConversation: 'Conversation',
    lastMsgAgent: 'Last msg agent',
    lastMsgUser: 'Last msg user',
    lastMessage: 'Last message',
    contactId: 'Facebook ID',
    noNewConversations: 'Do not accept new conversations',
    acceptNewConversation: 'Accept new conversations',
    writeNewMessage: 'Write a new message',
    emptyMessagesTitle: 'No messages',
    emptyMessagesText: 'Select a conversation from the panel in order to see the messages.',
    emptyConversations: 'No conversations',
    webchat: 'Web',
    whatsapp: 'WhatsApp',
    messenger: 'Messenger',
    showMoreMessages: 'Show more messages',
    endChatModal: {
      title: 'Do you want to end this conversation?',
      body:
        'If the conversation ends, the user will stop talking to a human agent and will talk to the chat bot again until he asks again to contact an agent again.',
      cancel: 'cancel',
      end: 'finish',
    },
    transferChatModal: {
      title: 'Do you want to transfer this conversation?',
      body:
        'You can transfer the conversation to the following agents, if the operation is not possible, the conversation will remain assigned to the original agent.',
      noAvailableAgents: 'There are no other agents available at this time to transfer this conversation to.',
      cancel: 'cancel',
      end: 'trnasfer',
    },
    template: 'Template',
    sent: 'sent',
    sendTemplate: 'Send template',
    notAbleToShowMessage: 'This message cannot be shown',
    notAbleToShowImageMessage: 'This message is an image and cannot be shown',
    notAbleToShowVideoMessage: 'This message is a video and cannot be shown',
    notAbleToShowDocMessage: 'This message is a document and cannot be shown',
    notAbleToShowLocationMessage: 'This message contains location data and cannot be shown',
    notAbleToShowContactMessage: 'This message contains contact data and cannot be shown',
    unknownDocumentCaption: 'Document',
    myStatus: 'My status:',
    unavailable: 'Unavailable',
    available: 'Available',
    online: 'Online',
    unknown: 'Unknown',
    transferConvToolTip: 'Transfer conversation',
    endconvToolTip: 'Finish conversation',
    scrollToBotToolTip: 'Jump to end',
    messageWasDeleted: 'This message was deleted',
    messageNotDelivered: 'The message could not be sent, try again later',
    sfDetails: 'Salesforce file',
    banUser: 'Ban user',
    banConversation: 'Ban conversation',
    banConversationList: 'Banned conversation list',
    ban: 'Ban',
    conversationId: 'Conversation ID',
    date: 'Date',
    unbanTooltip: 'Delete Ban',
    reasonBlocking: 'Indicate the reason for the blocking or any comment about it.',
    bannedSuccesfully: 'User banned sucessfully',
    unBannedSuccesfully: 'User unbanned sucesfully',
    listBannedUsers: 'List of banned users',
    areYouSureBanUserId: 'Would you like to block the conversation with ID',
    infoBanUser: '? This conversation will not be available again by the user while it is blocked',
    reason: 'Reason',
    logout: 'Logout',
    flowResponse: 'Flow response',
    key: 'Key',
    value: 'Value',
  },
  messageInput: {
    placeholder: 'Write your message...',
    sendTemplate: 'Send template',
    assignConvToMyself: 'Assign myself the conversation',
  },
  attachMenu: {
    image: 'Image',
    video: 'Video',
    document: 'Document',
    audio: 'Audio',
    attachButtonTooltip: 'Attach file',
  },
  attachImageModal: {
    title: 'Send image',
    button: 'Select image',
    send: 'Send',
    limitations: 'The image file size must not exceed 5 MB',
    supportedFormat: 'Admitted image formats: .jpeg, .jpg and .png',
  },
  attachVideoModal: {
    title: 'Send video',
    button: 'Select video',
    send: 'Send',
    limitations: 'The video file size must not exceed 16 MB',
    supportedFormat: 'Admitted video formats: .mp4 and .3gpp',
  },
  attachDocumentModal: {
    title: 'Send document',
    button: 'Select document',
    send: 'Send',
    limitations: 'The document file size must not exceed 100 MB',
    supportedFormat: '',
  },
  attachAudioModal: {
    title: 'Send audio',
    button: 'Select audio',
    send: 'Send',
    limitations: 'The audio file size must not exceed 100 MB',
    supportedFormat: 'Admitted audio formats:  .acc, .ogg, .amr, .mpeg y .mp4',
  },
  templateSelector: {
    title: 'Select template message',
    tooltip: 'Use template message',
    titleRadios: 'You can filter for role auditor',
    auditor: 'Auditor',
    all: 'All',
    addTemplate: 'Add template',
    deleteTemplate: 'Delete template',
    sendTemplate: 'Send template',
    createdTemplate: 'The template has been created sucessfully',
    deletedTemplate: 'The template has been deleted sucessfully',
    errorLoadingTemplates: 'There has been a problem loading the templates',
    errorCreatingTemplate: 'There has been a problem creating the template',
    errorDeletingTemplate: 'There has been a problem deleting the template',
    moreActions: 'More actions',
  },
  templateModal: {
    deleteTitle: 'Delete template',
    areYouSureDelete: 'Are you sure you want to delete the template?',
    createTitle: 'Create template',
    editTitle: 'Edit template',
    titlePlaceholder: 'Name of the template',
    messagePlaceholder: 'Template',
  },
  toast: {
    correctConvTransfer: 'The conversation has been successfully transferred to another agent.',
    sameAgentAfterTransfer: 'No agents available. This conversation will remain opened for this agente.',
    errorTransfer: 'No agents available. This conversation has been closed.',
    error500Attachfile: 'An error has occurred. Try again later and if the problem persists, contact the Indigitall technical support team.',
    supervisorCloseConversation: 'The conversation has been closed by a supervisor ',
    errorLoadUsers: 'Error trying to load available users.',
  },

  buttons: {
    cancel: 'Cancel',
    download: 'Download',
    downloadImage: 'Download image',
    downloadVideo: 'Download video',
    downloadDocument: 'Download document',
    downloadAudio: 'Download audio',
    save: 'Save',
    backToHome: 'Go back to home',
    transfer: 'Transfer',
  },
  multimedia: {
    playVideo: 'Click to watch the video',
  },
  modalSF: {
    title: 'Open file in Salesforce',
    body: 'Fill the Salesforce file',
    type: 'Select the type',
    reason: 'Select the reason',
    priority: 'Select the priority',
    description: 'Fill the description',
    labelType: 'Type',
    labelReason: 'Reason',
    labelPriority: 'Priority',
    labelDescription: 'Description',
    labelExternalId: 'External Id',
    saveAndCloseConv: 'Save and close',
  },
  modalCloseForm: {
    saveAndCloseConv: 'Save and close',
  },
  localPush: {
    title: 'Indigitall Contact Center',
    body: 'You have unread messages',
  },
  supervisor: {
    conversationsOf: "Agent {agentName}'s conversations",
    noMessagesAvailable: 'There are no messages available. Plese, go back and refresh the conversation.',
    noConversationsToShow: 'No conversations to show',
    agent: 'Agent #{agentId}',
    headerText: 'Conversations supervisor',
    agentsTab: 'Agents',
    statsTab: 'Statistics',
    online: 'Online',
    offline: 'Offline',
    seeConversations: 'See conversations',
    seeMessages: 'See messages',
    activeConversations: {
      singular: 'Active conversation',
      plural: 'Active conversations',
    },
    lastMessageReceived: 'Last message received',
    lastMessageSent: 'Last message sent',
    lastTimeConnected: 'Last time connected on',
    notAvailableAKA: 'N/A',
    changeToAvailable: 'Change status to Available',
    changeToUnavaible: 'Change status to Unavailable',
    statistics: {
      titleAgents: 'Agents',
      titleUsers: 'Users',
      titleConversations: 'Conversations',
      titleChannels: 'Channels',
      registeredAgentsPlural: 'registered agents',
      registeredAgentsSing: 'registered agent',
      onlineAgentsPlural: 'online agents',
      onlineAgentsSing: 'online agent',
      availableAgentsPlural: 'available agents',
      availableAgentsSing: 'available agent',
      quededUsersPlural: 'queded users',
      quededUsersSing: 'queded user',
      avgWaitTimeStr: 'The average waiting time is',
      maxWaitTimeStr: 'The maximum waiting time is',
      todayConvsAttendedPlural: 'conversations attended today',
      todayConvsAttendedSing: 'conversation attended today',
      openedConvsNowPlural: 'opened conversations right now',
      openedConvsNowSing: 'opened conversation right now',
      convsPerAgentSing: 'agent-assigned conversation',
      convsPerAgentPlural: 'agent-assigned conversations',
      convSing: 'conversation',
      convPlural: 'conversations',
    },
    modalTransfer: {
      title: 'Transfer conversation # {conversationId}',
      body: 'Select the agent to whom you want to transfer this discussion',
      selectPlaceholder: 'Write the agent name',
      agent: 'Agent #{agentId}',
      selectNoOptionsMessage: 'No options',
    },
    goBack: 'Go back',
  },
  emojiPicker: {
    title: 'Choose your emoji',
    search: 'Search',
    notfound: 'No Emoji Found',
    skintext: 'Choose your default skin tone',
    categories: {
      search: 'Search Results',
      recent: 'Frequently Used',
      people: 'Smileys & People',
      nature: 'Animals & Nature',
      foods: 'Food & Drink',
      activity: 'Activity',
      places: 'Travel & Places',
      objects: 'Objects',
      symbols: 'Symbols',
      flags: 'Flags',
      custom: 'Custom',
    },
  },
  login: {
    recoveryPass: 'Recovery pass',
    title: 'Login',
    email: 'Email',
    pass: 'Password',
    login: 'Login',
    doubleFactorSummary: 'Insert the verification code received in your email, the code will be valid for:',
    verificationCode: 'Verification code',
    send: 'Send',
    backToLogin: 'Back to login',
    refreshToken: 'Get new code',
    userLockedInactivity: 'User is locked due to inactivity. Please, contact an administrator',
    tooOldPassword: 'Your password is too old. An email has been sent to your email, follow the instructions to change it',
    tooManyAttempts: 'Too many login attempts, the user is blocked, try again later or contact your administrator.',
    invalidBadges: 'Invalid credentials',
  
    insertVerificationCode: 'Insert the verification code received in your email, the code will be valid for',
    invalidTotpError: 'The code entered is not valid',
    tokenExpiredError: 'The code entered has expired',
    sendNewCode: 'Get new code',
    isNotValid: 'is not valid',
    invitationExpired: 'Expired invitation',
  },
  applications: {
    title: 'Select a project',
    noApplications: 'No projects available',
    loadMore: 'Load more',
    applicationSelected: 'Project selected',
    change: 'Change',
  },
  modalInstallApp: {
    title: 'Add Indigitall Contact Center to your Home Screen',
    body: `To have a better experience we encourage you to install the app in your mobile devicePixelRatio, so you
      can access it quicker and enjoy our App`,
    install: 'Install',
    cancel: 'Cancel',
  },
  previewTemplateModal: {
    title: 'Template preview',
    body: '',
    close: 'Close',
  },
};
